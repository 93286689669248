import { Component, Vue, Prop } from 'vue-property-decorator'
import { Metric } from '@/Models'

@Component({
  components: {},
})
export default class ReporteBSC extends Vue {
  @Prop({ required: true })
  metricProp!: Metric

  dayName = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes']
  monthName = [
    'ENE',
    'FEB',
    'MAR',
    'ABR',
    'MAY',
    'JUN',
    'JUL',
    'AGO',
    'SEP',
    'OCT',
    'NOV',
    'DIC',
  ]
  index_month: any = {
    ENE: 0,
    FEB: 1,
    MAR: 2,
    ABR: 3,
    MAY: 4,
    JUN: 5,
    JUL: 6,
    AGO: 7,
    SEP: 8,
    OCT: 9,
    NOV: 10,
    DIC: 11,
  }
  activity = ''
  indicator = ''
  department = ''
  type = ''
  dataSet: any[] = []
  metric_labels: string[] = []

  async mounted() {
    this.dataSet = new Array<any>(12).fill({
      value: '',
    })
    this.activity = this.metricProp.matrix_metric.activity.title
    //console.log(this.metricProp)
    this.indicator = this.metricProp.indicator?.title as string
    this.department = this.metricProp.department.name as string
    this.type = this.metricProp.matrix_metric.metric_type
    if (this.metricProp) {
      switch (this.metricProp.matrix_metric.metric_type) {
        case 'diary':
          this.metric_labels = this.metricProp.metric_dataset.data_set.map(
            (x: any) =>
              this.parseDay(x.dayName) +
              new Date(x.date).toISOString().split('T')[0].substring(4),
          )
          break
        case 'weekly':
          this.metric_labels = this.metricProp.metric_dataset.data_set.map(
            (x: any) =>
              x.weekRange.substring(0, x.weekRange.indexOf('to') - 6) +
              ' - ' +
              x.weekRange.substring(
                x.weekRange.indexOf('to') + 3,
                x.weekRange.length - 5,
              ),
          )
          // x.weekRange.slice(0, x.weekRange.lastIndexOf('/')),
          break
        case 'monthly':
          // this.metric_labels = this.metricProp.metric_dataset.data_set.map(
          //   (x: any) => x.month,
          // )
          this.dataSet = new Array<any>(12).fill({
            date: '',
            month: '',
            status: '',
            type: 'is-primary',
            value: '',
          })
          this.metric_labels = this.monthName.reverse()
          break
      }
      this.metric_labels = this.metric_labels.reverse()
    }

    let index_max = 12
    if (this.metricProp.metric_dataset.data_set.length < 12) {
      let number_add = 12 - this.metricProp.metric_dataset.data_set.length
      while (number_add > 0) {
        number_add--
        this.metricProp.metric_dataset.data_set.push({})
      }
    }
    const rever = this.metricProp.metric_dataset.data_set.reverse()
    rever.forEach((d: any) => {
      index_max--
      if (index_max < 0) return

      if (d.value) {
        //validacion no sirve xD
        if (this.metricProp.matrix_metric.metric_type === 'monthly') {
          // this.dataSet[index_max] = d
          this.dataSet[Number(this.index_month[`${d['month']}`])] = d
        } else this.dataSet[index_max] = d
      }
      // else {
      //   this.dataSet.push({})
      // }
    })
  }

  parseDay(day: string) {
    switch (day) {
      case 'Lunes':
        return 'L'
        break
      case 'Martes':
        return 'K'
        break
      case 'Miercoles':
        return 'M'
        break
      case 'Jueves':
        return 'J'
        break
      case 'Viernes':
        return 'V'
        break
      case 'Sabado':
        return 'S'
        break
      case 'Domingo':
        return 'D'
        break
    }
  }
}
