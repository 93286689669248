var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card mt-5"},[_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.metricProp.title))]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"mb-4 is-size-3 has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t(_vm.metricProp.perspective_id))+" ")])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-5 ml-3"},[_c('b-tooltip',{attrs:{"label":_vm.activity,"multilined":""}},[_c('b-field',{attrs:{"label":"Elemento Guía","label-position":'on-border'}},[_c('b-input',{attrs:{"disabled":"","value":_vm.activity.length > 12
                          ? _vm.activity.substring(0, 12) + '...'
                          : _vm.activity}})],1)],1)],1),_c('div',{staticClass:"column is-5"},[_c('b-tooltip',{attrs:{"label":_vm.indicator,"multilined":""}},[_c('b-field',{attrs:{"label":"Indicador","label-position":'on-border'}},[_c('b-input',{attrs:{"disabled":"","value":_vm.indicator.length > 20
                          ? _vm.indicator.substring(0, 12) + '...'
                          : _vm.indicator}})],1)],1)],1),_c('div',{staticClass:"column is-5 ml-3"},[_c('b-tooltip',{attrs:{"label":_vm.department,"multilined":""}},[_c('b-field',{attrs:{"label":"Departamento","label-position":'on-border'}},[_c('b-input',{attrs:{"disabled":"","value":_vm.department.length > 16
                          ? _vm.department.substring(0, 12) + '...'
                          : _vm.department}})],1)],1)],1),_c('div',{staticClass:"column is-5"},[_c('b-tooltip',{attrs:{"label":_vm.$t(_vm.type)}},[_c('b-field',{attrs:{"label":"Tipo","label-position":'on-border'}},[_c('b-input',{attrs:{"disabled":"","value":_vm.$t(_vm.metricProp.matrix_metric.metric_type)}})],1)],1)],1)])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"columns is-multiline mr-3"},_vm._l((_vm.dataSet),function(item,index){return _c('div',{key:index,staticClass:"column is-1"},[(item.value !== '')?[_c('small',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(_vm.metric_labels[index])+" ")]),_c('br'),_c('b-tooltip',{attrs:{"label":item.value,"multilined":""}},[_c('p',{staticClass:"card-footer-item p-4 has-text-white",style:(`background-color:${item.status};`)},[_vm._v(" "+_vm._s(item.value.length > 4 ? item.value.substring(0, 4) + '...' : item.value)+" ")])])]:[_c('small',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(_vm.metricProp.matrix_metric.metric_type == 'monthly' ? _vm.metric_labels[index] : '')+" ")]),_c('br'),_c('small',{staticClass:"card-footer-item p-2 has-text-white",style:(`background-color:grey; width: 54px 
                  `)},[_vm._v(" Sin valor ")])]],2)}),0)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }